.app {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
}
.triangle {
  width: 0;
  height: 10;
  border-style: solid;
  border-width: 0 30vw 300px 70vw;
  border-color: transparent transparent #b9c9c3 transparent;
}
.triangle-base {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.header-row {
  width:100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  -webkit-box-shadow: 0px 7px 5px 0px rgba(0,0,0,0.23);
  box-shadow: 0px 7px 5px 0px rgba(0,0,0,0.23);
}
.header-contact {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.header-titles {
  margin-right: 20px;
}
.header-title {
  margin-right: 20px;
  font-size: 21px;
  cursor:pointer;
}
.header-title-main {
  margin-right: 20px;
  font-size: 21px;
  cursor:pointer;
  border-bottom: 1px solid black;
}

.header-title:hover {
  border-bottom: 1px solid black;
}


.project-row {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.project-col {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}
.project-items {
  align-items: center;
  cursor:pointer;
  margin-top: 8px;
}

.footer-bottom {
  position: absolute;
  bottom: 3px;
}

.footer-row {
  height: min(110px, 11vw);
  width:100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.footer-title {
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.footer-image {
  cursor:pointer;
  margin: 8px;
}
.footer-img {
  width: min(50px,5vw);
  resize: contain;
}

.footer-image:hover {
  border-bottom: 8px solid transparent;
}

.reorder-overlay {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.reorder-text {
  cursor:pointer;
  margin: 15px;
  font-size: 25px;
}




.small-text {
  font-size: 15px;
}
.medium-text {
  font-size: 18px;
}
.large-text {
  font-size: 22px;
}

.margin-15 {
  margin: 15px;
}

.margin-5 {
  margin: 5px;
}
