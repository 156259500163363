.App {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.block {
  width: min(12vw,12vh);
  height: min(12vw,12vh);
  margin: min(1vw,1vh);
  border-radius: 8px;
  cursor:pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(4vw,4vh);
  background-color: teal;
  color: white;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: center;
  padding-top: 3vh;
}

.block:hover {
  -webkit-box-shadow: 5px 5px 15px 5px #000000;
  box-shadow: 5px 5px 15px 5px #000000;
}

.reset {
  float: left;
  background-color: teal;
  margin-left: 15px;
  margin-top: 15px;
  border-radius: 5px;
  padding: 3px;
  font-size: 25px;
  cursor: pointer;
  color: white;
}

.title {
  font-size: 25px;
  padding-top: 2px;
}
