.project-back-view {
  width: min(500px,90vw);
}

.project-back-button {
  padding: 5px;
  width: 55px;
  cursor:pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-center-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-img {
  height: min(250px,25vw);
  resize: contain;
}

.project-img-small {
  width: min(250px,25vw);
  resize: contain;
}

.project-select {
  cursor:pointer;
}
